import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { HistogramChartComponent } from "../histogram-chart/histogram-chart.component";

@Component({
  selector: "logs",
  templateUrl: "./logs.component.html",
  styleUrls: ["./logs.component.scss"],
})
export class LogsComponent {
  @Input() activeFormat: string;
  @Input() logs = [];
  @Input() version: string;
  @Input() server_id: string;
  @Input() activeProject: any;
  @Output() clear = new EventEmitter<string>();
  @Output() isLive = new EventEmitter<string>();
  limitError = 100;
  limitFunction = 100;
  scroll_length = 500;
  worker_logs;
  // Setting start day as yesterday from now.
  endDate: Date = new Date();
  startDate: Date = new Date();
  isScrollRight: boolean = false;
  isScrollLeft: boolean = false;
  checkIsLive: boolean = false;
  showErrors: boolean = false;
  showStatics: boolean = true;
  showFunctionLogs: boolean = true;
  showWorkerLogs: boolean = true;

  interval;

  functionsLogs: { functionName: string; funcLogs: any[] }[] = [];
  allLogs: any[] = [];
  errorLogs: [] = [];
  leftSideHeight: Number = 0;
  activeLog: string;
  isAvailableVersion: boolean = false;
  progress = {
    worker_top: false,
    log: false,
    log_bottom: false,
    function_info: false,
    worker_bottom: false,
    error: false,
  };
  workerStatus;
  bottomScrollStep: number = 1;
  @ViewChild("logHistogram") histogram_chart: HistogramChartComponent;
  @ViewChild("left_side") left_side: ElementRef;
  @ViewChild("right_side") right_side: ElementRef;
  @ViewChild("right_side_content") right_side_content: ElementRef;

  constructor(private cdref: ChangeDetectorRef, private http: HttpClient) {}

  ngOnInit(): void {
    this.getAllLogs();
    console.log(this.functionsLogs);
    console.log(this.progress.function_info);
  }
  clearLogs(clear_log_type) {
    if (clear_log_type == "worker") {
      this.worker_logs = [];
    } else this.clear.emit();
  }
  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  setTimeRange(timeInMilliseconds: number) {
    this.endDate = new Date();
    this.startDate = new Date(Date.now() - timeInMilliseconds);
  }
  //Select function for calendar
  onSelectDate(e, dateType: string) {
    if (dateType == "start") {
      this.startDate = e;
    } else {
      this.endDate = e;
    }
  }
  async setGraph(position?) {
    this.progress.log = true;
    if (this.startDate.getTime() - this.endDate.getTime() == 0) {
      this.startDate.setHours(this.endDate.getHours() - 8);
    }
    await this.http
      .get(
        `https://${
          this.activeProject.project.server.ip_address
        }/api/function-logs?sort={"_id":-1}&begin=${this.startDate.toISOString()}&end=${this.endDate.toISOString()}&limit=${
          position === "bottom"
            ? this.showErrors
              ? this.showFunctionLogs
                ? ""
                : this.limitError
              : this.showFunctionLogs
              ? this.limitFunction
              : ""
            : "0"
        }&${
          this.showErrors
            ? this.showFunctionLogs
              ? ""
              : "channel=stderr"
            : this.showFunctionLogs
            ? "channel=stdout"
            : ""
        }`,
        {
          headers: {
            Authorization: `APIKEY ${this.activeProject.project.server.system_api_key}`,
          },
        }
      )
      .toPromise()
      .then((logData) => {
        this.functionsLogs = this.groupBy();
        setTimeout(() => {
          this.histogram_chart?.createGraphData(
            this.startDate,
            this.endDate,
            logData
          );
        }, 100);
        this.progress.log = false;
      });
  }
  scrollLog(event, side) {
    if (side == "left") {
      this.isScrollLeft = event.target.scrollTop == 0 ? false : true;
      let position_left;
      if (
        event.target.scrollTop ==
          this.left_side.nativeElement.scrollHeight -
            this.left_side.nativeElement.offsetHeight &&
        this.bottomScrollStep < 4
      ) {
        this.left_side.nativeElement.scrollTop -=
          this.left_side.nativeElement.offsetHeight / 2;
        position_left = "bottom";
      }
      if (
        !this.isScrollLeft &&
        !this.checkIsLive &&
        !this.progress.log &&
        this.allLogs.length > 0
      ) {
        this.progress.log = true;
        this.isLiveLog(false);
        this.setGraph();
      } else if (
        position_left === "bottom" &&
        !this.checkIsLive &&
        !this.progress.log &&
        this.allLogs.length > 0
      ) {
        if (this.showErrors) {
          this.limitError = this.limitError + 40;
          this.progress.log_bottom = true;
          this.getAllErrors(position_left);
          this.setGraph(position_left);
          this.progress.log_bottom = false;
        } else if (this.showFunctionLogs) {
          this.limitFunction = this.limitFunction + 40;
          this.progress.log_bottom = true;
          this.getAllLogs(position_left);
          this.setGraph(position_left);
          this.progress.log_bottom = false;
        }
      }
    }
    if (side == "right") {
      let position;
      if (event.target.scrollTop == 0) {
        position = "top";
        this.isScrollRight = false;
      } else {
        this.isScrollRight = true;
        if (
          event.target.scrollTop ==
            this.right_side_content.nativeElement.scrollHeight -
              this.right_side_content.nativeElement.offsetHeight &&
          this.bottomScrollStep < 4
        ) {
          position = "bottom";
          this.progress.worker_bottom = true;
          this.bottomScrollStep += 1;
        }
      }
      if (!position) return;
      this.getWorkerLogs(position);
    }
  }
  ngAfterViewChecked() {
    this.isAvailableVersion =
      Number(this?.version.substr(0, 3)) > 0.8 ? true : false;
    this.cdref.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.server_id) {
      this.worker_logs = [];
      this.workerStatus = {};
      this.getWorkerLogs(null);
    }

    if (changes.logs) {
      this.progress.log = false;
    }
  }
  async getAllErrors(position?) {
    this.showErrors = true;
    this.progress.error = true;
    await this.http
      .get(
        `https://${this.activeProject.project.server.ip_address}/api/function-logs?sort={"_id":-1}&limit=${this.limitError}`,
        {
          headers: {
            Authorization: `APIKEY ${this.activeProject.project.server.system_api_key}`,
          },
        }
      )
      .toPromise()
      .then((errors) => {
        this.errorLogs = errors as [];

        this.progress.error = false;
      });
  }
  async getAllLogs(position?) {
    this.progress.function_info = true;
    await this.http
      .get(
        `https://${this.activeProject.project.server.ip_address}/api/function-logs?sort={"_id":-1}&limit=${this.limitFunction}`,
        {
          headers: {
            Authorization: `APIKEY ${this.activeProject.project.server.system_api_key}`,
          },
        }
      )
      .toPromise()
      .then((logs) => {
        this.allLogs = logs as [];
        this.functionsLogs = this.groupBy();
        this.startDate.setHours(this.endDate.getHours() - 8);
        this.setGraph();
      });
  }
  sortFunctions(a, b) {
    return a.value.length > b.value.length ? -1 : 1;
  }
  groupBy() {
    let property = "function";
    this.progress.function_info = false;
    return this.allLogs.reduce(function (memo, x) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }
  isLiveLog(event) {
    this.checkIsLive = event;
    this.isLive.emit(event);
  }
  async getWorkerLogs(position) {
    let timestamp = new Date();
    if (position == "bottom") {
      timestamp.setMinutes(timestamp.getMinutes() - 30 * this.bottomScrollStep);
    } else {
      timestamp.setMinutes(timestamp.getMinutes() - 30);
      this.progress.worker_top = true;
    }

    let data = await this.http
      .get(
        `https://hq.spicaengine.com/api/fn-execute/workerLogs?server_id=${
          this.server_id
        }&worker_timestamp=${timestamp.toISOString()}`,
        {
          headers: {
            Authorization: `IDENTITY ${localStorage.getItem("hq_token")}`,
          },
        }
      )
      .toPromise();
    this.workerStatus = data["workerInfo"];
    data["logs"].forEach((element) => {
      if (!this.worker_logs.some((wl) => wl.time == element.time))
        this.worker_logs.push(element);
    });
    this.worker_logs = this.worker_logs.sort(
      (f, l) => new Date(l.time).getTime() - new Date(f.time).getTime()
    );
    this.progress.worker_bottom = false;
    this.progress.worker_top = false;
  }
}
