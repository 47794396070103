import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Project } from "src/app/interfaces/project";

@Component({
  selector: "doc-functions",
  templateUrl: "./doc-functions.component.html",
  styleUrls: ["./doc-functions.component.scss"],
})
export class DocFunctionsComponent implements AfterViewInit {
  @Input() activeProject: Project;
  @Input() functions;
  @Input() activeFormat: string;
  @Input() logs;
  @Output() clear = new EventEmitter<string>();
  isScroll: boolean = false;
  leftSideHeight: Number = 0;
  @ViewChild("left_side") left_side: ElementRef;

  constructor(private cdref: ChangeDetectorRef) {}
  clearLogs() {
    this.clear.emit();
  }
  scrollLog(event) {
    this.isScroll = event.target.scrollTop == 0 ? false : true;
  }
  ngAfterViewInit() {
    this.setLeftSideHeight();
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.setLeftSideHeight();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.left_side ? this.setLeftSideHeight() : "";

    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
  }
  setLeftSideHeight() {
    this.leftSideHeight = this.left_side.nativeElement.offsetHeight;
    this.cdref.detectChanges();
  }
}
