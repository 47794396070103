<div [class.monitoring]="activeFormat == 'monitoring'">
  <div class="functions-list">
    <h1 class="header">
      {{ activeFormat == "monitoring" ? "Functions" : "Function List" }}
    </h1>
    <div class="content">
      <div class="left-side" #left_side>
        <div class="function" *ngFor="let function of functions">
          <h2 class="name">{{ function.name }}</h2>
          <div class="timeout">Timeout: {{ function.timeout }}s</div>
          <div class="description" *ngIf="activeFormat != 'monitoring'">
            {{ function.description }}
          </div>
          <div class="triggers">
            <doc-function-trigger
              [trigger]="trigger"
              *ngFor="let trigger of function.triggers"
            >
            </doc-function-trigger>
          </div>
          <div class="envs" *ngIf="function.env.length == 123">
            <h3 class="header">Environment Variables</h3>
            <div class="env" *ngFor="let env of function.env">
              <span>{{ env[0] }}</span
              ><span>{{ env[1] }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
