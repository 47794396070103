import { Injectable } from '@angular/core';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";


@Injectable({
  providedIn: 'root'
})
export class PdfDownloadService {

  constructor() { }

  downloadPdf(htmlRef){
    return html2canvas(htmlRef, { allowTaint: true }).then((canvas) => {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + top_left_margin * 3;
      //let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let PDF_Height =
        HTML_Height + top_left_margin * 3 > 10000
          ? 2000
          : HTML_Height + top_left_margin * 3;
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      canvas.getContext("2d");
      let imgData = canvas.toDataURL("image/jpeg");
      let pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], "p");
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height
        );
        //  pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save("HTML-Document.pdf");
    });
  }
}
