<div class="header-background">
  <div class="navigator">
    <h2 class="text-white">Spica Engine Observer</h2>
    <h3 class="text-white"><a *ngIf="user" mat-icon-button
        [href]="'https://dashboard.spicaengine.com/login?callback='+host+'&operation=logOut'">
        <mat-icon>logout</mat-icon>
      </a>
      {{user?.identifier}}</h3>
  </div>
</div>
<div class="main">
  <router-outlet></router-outlet>
</div>

<iframe src="https://hq.spicaengine.com/api/fn-execute/v1/state" id="hq-iframe" style="display: none"></iframe>