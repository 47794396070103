import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  //hq_token;
  user;
  user_onchange: Subject<any> = new Subject();

  constructor() { }

  getUser() {


    if (!this.user) {
      try {
        this.user = jwt_decode(this.getHqToken());
      } catch (error) {
        console.log("error :", error)
      }
    }
    return this.user;
  }

  getHqToken() {
    return localStorage.getItem("hq_token");
  }

  deleteLocalStorage() {
    localStorage.clear();
    window.location.reload();
  }

  setHqToken(hq_token) {
    // this.hq_token = hq_token;
    this.user_onchange.next(this.user);
    localStorage.setItem("hq_token", hq_token);
  }
}
