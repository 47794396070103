<div class="monitoring">
  <div class="toggle-container">
    <mat-slide-toggle
      *ngIf="isAvailableVersion"
      (change)="isLiveLog($event.checked)"
      >Live Logs</mat-slide-toggle
    >
    <mat-slide-toggle
      [checked]="showStatics"
      (change)="showStatics = $event.checked"
      >Statistics</mat-slide-toggle
    >

    <mat-slide-toggle
      [checked]="showErrors"
      (change)="getAllErrors($event.checked); setGraph()"
      >Errors</mat-slide-toggle
    >
    <mat-slide-toggle
      (change)="showFunctionLogs = $event.checked; setGraph()"
      [checked]="showFunctionLogs"
      >Functions</mat-slide-toggle
    >
    <mat-slide-toggle
      (change)="showWorkerLogs = $event.checked"
      [checked]="showWorkerLogs"
      >Workers</mat-slide-toggle
    >
  </div>
  <div class="container">
    <div
      class="left-side"
      *ngIf="showStatics || showWorkerLogs"
      [class.active]="!showFunctionLogs && !showErrors"
    >
      <div class="statistics" *ngIf="showStatics">
        <div class="functions" [class.active]="!showWorkerLogs">
          <div class="functions-title">
            <div>Log Count</div>
          </div>
          <div class="no-statictic" *ngIf="functionsLogs">
            There is no function log yet!
          </div>
          <div #right_side class="statistic-list">
            <div
              class="statictic-function"
              *ngFor="let func of functionsLogs | keyvalue : sortFunctions"
            >
              <div>
                <span>{{ func.key }} </span> {{ func.value.length }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="worker-container"
        [class.active]="!showErrors && !showFunctionLogs"
        *ngIf="showWorkerLogs"
      >
        <div class="clear-row" [class.scroll-worker-log]="isScrollRight">
          <div>
            Worker Logs
            <span
              *ngIf="workerStatus && workerStatus.active"
              class="worker-status"
              >(Active Workers : {{ workerStatus.active }} /
              {{ workerStatus.limit }})</span
            >
          </div>
          <span
            title="Clear"
            class="clear-button"
            (click)="clearLogs('worker')"
          >
            <mat-icon>clear_all</mat-icon>
          </span>
        </div>
        <div
          #right_side_content
          (scroll)="scrollLog($event, 'right')"
          [class.stop-scroll]="progress.worker_bottom || progress.worker_top"
          class="workers"
          [class.active]="!showStatics"
        >
          <div
            class="no-log"
            *ngIf="worker_logs?.length == 0 && !progress.worker_top"
          >
            There is no log yet !
          </div>
          <mat-spinner
            class="new-log-spinner"
            [diameter]="20"
            *ngIf="progress.worker_top"
          ></mat-spinner>
          <div #right_side class="log-list">
            <div class="log" *ngFor="let log of worker_logs">
              <div>
                <span>{{ log.time | date : "d/MM HH:mm" }} </span>
                {{ log.text }}
              </div>
            </div>
            <div class="log" *ngIf="progress.worker_bottom">
              <mat-spinner
                class="new-log-spinner bottom"
                [diameter]="20"
              ></mat-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="right-side"
      *ngIf="showFunctionLogs || showErrors"
      [class.active]="!showWorkerLogs && !showStatics"
    >
      <div class="graph-date-container" *ngIf="allLogs && functionsLogs">
        <div class="graph-date-menu">
          <button
            mat-button
            [matMenuTriggerFor]="menu"
            class="graph-date-menu-button"
          >
            Time Range
          </button>
          <mat-menu class="time-range-menu" #menu="matMenu">
            <button
              mat-menu-item
              (click)="$event.stopPropagation(); setTimeRange(30000)"
            >
              30 seconds
            </button>
            <button
              mat-menu-item
              (click)="$event.stopPropagation(); setTimeRange(900000)"
            >
              15 minutes
            </button>
            <button
              mat-menu-item
              (click)="$event.stopPropagation(); setTimeRange(28800000)"
            >
              8 hours
            </button>
            <button
              mat-menu-item
              (click)="$event.stopPropagation(); setTimeRange(604800000)"
            >
              7 days
            </button>
            <button
              mat-menu-item
              [matMenuTriggerFor]="dateMenu"
              (click)="$event.stopPropagation()"
            >
              Custom date range
            </button>
            <div class="time-range-menu-footer">
              <button mat-button>Cancel</button>
              <button mat-button (click)="setGraph()">Apply</button>
            </div>
          </mat-menu>
          <mat-menu #dateMenu="matMenu" class="date-picker-menu">
            <div class="date-picker-container">
              <div
                class="date-picker-calendar"
                (click)="$event.stopPropagation()"
              >
                <b>From</b>
                <mat-calendar
                  [selected]="startDate"
                  [maxDate]="endDate"
                  (selectedChange)="onSelectDate($event, 'start')"
                ></mat-calendar>
              </div>
              <div
                class="date-picker-calendar"
                (click)="$event.stopPropagation()"
              >
                <b>To</b>
                <mat-calendar
                  [minDate]="startDate"
                  [selected]="endDate"
                  (selectedChange)="onSelectDate($event, 'end')"
                ></mat-calendar>
              </div>
            </div>
          </mat-menu>
        </div>
        <mat-spinner
          class="graph spinner"
          [diameter]="30"
          *ngIf="progress.log || !allLogs || !functionsLogs"
        ></mat-spinner>
        <app-histogram-chart
          *ngIf="!progress.log && allLogs && functionsLogs"
          #logHistogram
          [functionsLogs]="functionsLogs"
          [allLogs]="allLogs"
        ></app-histogram-chart>
      </div>
      <div class="function-logs" *ngIf="showFunctionLogs || showErrors">
        <div class="logs" #left_side (scroll)="scrollLog($event, 'left')">
          <div class="log-header" [class.scroll-log]="isScrollLeft">
            <div class="log-func">Function</div>
            <div class="log-desc">Description</div>
            <div class="log-date" [class.withDelete]="isAvailableVersion">
              Date
              <span
                *ngIf="isAvailableVersion"
                title="Clear"
                class="clear-button"
                (click)="clearLogs('log')"
              >
                <mat-icon>clear_all</mat-icon>
              </span>
            </div>
          </div>
          <!-- <div class="no-log" *ngIf="!isAvailableVersion">You need to upgrade your version to 0.9 or higher !</div> -->
          <div
            *ngIf="
              logs.length == 0 &&
              !progress.log &&
              errorLogs.length == 0 &&
              !progress.error
            "
            class="no-log"
          >
            There is no log yet !
          </div>
          <mat-spinner
            class="new-log-spinner"
            [diameter]="20"
            *ngIf="progress.log || progress.error"
          ></mat-spinner>
          <div *ngIf="showFunctionLogs && showErrors">
            <div
              *ngFor="let log of errorLogs"
              class="log"
              [class.active]="log?._id == activeLog"
              (click)="
                activeLog != log?._id
                  ? (activeLog = log?._id)
                  : (activeLog = undefined)
              "
            >
              <div class="log-func">{{ log?.function }}</div>
              <div class="log-desc">
                <pre>
                  <mat-icon *ngIf="log.channel == 'stderr'"> error </mat-icon>
                  {{ log?.content }}
                 </pre>
              </div>
              <div class="log-date">
                {{ log?.created_at | date : "d/MM/yy HH:mm:ss" }}
              </div>
            </div>
            <div class="new-log-spinner" *ngIf="progress.log_bottom">
              <mat-spinner
                class="new-log-spinner bottom"
                [diameter]="20"
              ></mat-spinner>
            </div>
          </div>
          <div *ngIf="showFunctionLogs && !showErrors">
            <div
              *ngFor="let log of logs"
              class="log"
              [class.active]="log?._id == activeLog"
              (click)="
                activeLog != log?._id
                  ? (activeLog = log?._id)
                  : (activeLog = undefined)
              "
            >
              <div class="log-func">{{ log?.function }}</div>
              <div class="log-desc">
                <pre>{{ log?.content }}</pre>
              </div>
              <div class="log-date">
                {{ log?.created_at | date : "d/MM/yy HH:mm:ss" }}
              </div>
            </div>
            <div class="log" *ngIf="progress.log_bottom">
              <mat-spinner
                class="new-log-spinner bottom"
                [diameter]="20"
              ></mat-spinner>
            </div>
          </div>
          <div *ngIf="showErrors && !showFunctionLogs">
            <div
              *ngFor="let err of errorLogs"
              class="log"
              [class.active]="err?._id == activeLog"
              (click)="
                activeLog != err?._id
                  ? (activeLog = err?._id)
                  : (activeLog = undefined)
              "
            >
              <div class="log-func">{{ err?.function }}</div>
              <div class="log-desc">
                <pre>
                  <mat-icon> error </mat-icon>
                  {{ err?.content }}
                 </pre>
              </div>
              <div class="log-date">
                {{ err?.created_at | date : "d/MM/yy HH:mm:ss" }}
              </div>
            </div>
            <div class="log" *ngIf="progress.log_bottom">
              <mat-spinner
                class="new-log-spinner bottom"
                [diameter]="20"
              ></mat-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
