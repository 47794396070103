<div class="buckets-list">
  <h1 class="header">
    Bucket List
    <button class="text-white" *ngIf="!pdfView" mat-button (click)="export()">
      Export As JSON Schema
    </button>
  </h1>
  <div class="bucket" *ngFor="let bucket of buckets">
    <div
      class="meta"
      (click)="
        activeBucket == bucket._id
          ? (activeBucket = undefined)
          : (activeBucket = bucket._id)
      "
    >
      <h2 class="name">{{ bucket.title }}</h2>
      <div class="description">{{ bucket.description }}</div>
    </div>
    <div class="apis" *ngIf="pdfView || activeBucket == bucket._id">
      <div class="api {{ api.method }}" *ngFor="let api of possibleAPIs">
        <div
          class="method"
          (click)="
            toggleAPI(bucket._id, bucket._id + api.method, bucket.properties)
          "
        >
          {{ api.method }}
        </div>
        <div class="url">
          https://{{ activeProject.server.ip_address }}/api/buckets/{{
            bucket._id
          }}/{{ api.path }}
        </div>
        <div
          class="extra-content"
          *ngIf="
            exampleObjects[bucket._id] &&
            activatedAPIs.includes(bucket._id + api.method)
          "
        >
          <div class="code"></div>

          <pre>{{
            api.method == "GET ALL"
              ? [
                  exampleObjects[bucket._id] | json,
                  exampleObjects[bucket._id] | json
                ]
              : (exampleObjects[bucket._id] | json)
          }}</pre>
        </div>
      </div>
    </div>
    <div class="data-model" *ngIf="pdfView || activeBucket == bucket._id">
      <h3>Data Model</h3>
      <doc-rest-properties
        [properties]="bucket.properties"
      ></doc-rest-properties>
    </div>
  </div>
</div>
