import { Component, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UsageComponent } from "src/app/chart/usage/usage.component";
import { ObserverService } from "../../services/observer.service";
import { Observable, Subject } from "rxjs";
import { DataService } from "src/app/services/data.service";
import { AuthService } from "src/app/services/auth.service";
import { Project } from "src/app/interfaces/project";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-graph-standalone",
  templateUrl: "./graph-standalone.component.html",
  styleUrls: ["./graph-standalone.component.scss"],
})
export class GraphStandaloneComponent implements OnInit {
  serverDetails: any;
  chartType: string;
  period: number = 120; //Default period
  before: number = 1;
  isProcessing: boolean;
  interval;
  activeProject: any;
  activeProjectId: string;
  userProjects: Observable<Project[]>;
  project: any;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild("apiCpuUsageComp") apiCpuUsageComp: UsageComponent;
  @ViewChild("apiRamUsageComp") apiRamUsageComp: UsageComponent;
  @ViewChild("databaseCpuUsageComp") databaseCpuUsageComp: UsageComponent;
  @ViewChild("databaseRamUsageComp") databaseRamUsageComp: UsageComponent;
  @ViewChild("storageCdnUsageComp") storageCdnUsageComp: UsageComponent;
  @ViewChild("apiDiscUsageComp") apiDiscUsageComp: UsageComponent;
  @ViewChild("databaseDiscUsageComp") databaseDiscUsageComp: UsageComponent;

  constructor(
    private observerService: ObserverService,
    private authService: AuthService,
    private dataService: DataService,
    private route: ActivatedRoute
  ) { }
  ngOnInit(): void {
    this.userProjects = this.dataService.resources.projects
      .getAll({
        queryParams: {
          relation: "server.plan",
          filter: {
            "user._id": { $ne: "" },
            $and: [
              { "user.identity_id": this.authService.getUser()._id },
              {
                "server.observer_activated_date": {
                  $lt: `Date(${new Date().toISOString()})`,
                },
              },
              { status: { $in: ["active", "requested", "denied", "expired"] } },
              { package_code: { $nin: ["on_premise"] } },
            ],
          },
        },
      })
      .pipe(
        tap((data: Project[]) => {
          this.route.params.subscribe((routeParams) => {
            this.activeProjectId = routeParams.id;
            this.chartType = routeParams.type;
          });
          let active = data.filter(
            (project) => project._id == this.activeProjectId
          )[0];
          this.activeProject = active;
          this.setGraphParam(active);
        })
      ) as Observable<Project[]>;
    this.userProjects.subscribe();
    this.interval = setInterval(() => {
      this.setGraphParam(this.project);
    }, 60000);
  }

  setGraphParam(project: Project) {
    let startTime = new Date();
    startTime.setHours(new Date().getHours() - this.before);
    this.project = project;
    this.observerService
      .getServerDetails(
        this.project.server._id,
        startTime,
        new Date(),
        this.period
      )
      .toPromise()
      .then((data) => {
        this.serverDetails = data;
        if (
          this.serverDetails.data?.cdn_storage &&
          Array.isArray(this.serverDetails.data?.cdn_storage)
        )
          this.serverDetails.data?.cdn_storage.forEach((element) => {
            //  element.value.doubleValue = element.value.doubleValue / (1024 * 1024);
            element.value.doubleValue =
              element.value.doubleValue /
              (10 * 1000 * 1024 * 1024) /
              parseInt(this.project.server.plan.storage.storage);
            return element;
          });
        else this.serverDetails.data.cdn_storage = [];

        this.project.server.server_conf = {
          api_backend_lim_cpu: parseInt(
            JSON.parse(this.project.server.plan.resources.api).limits.cpu
          ),
          api_backend_lim_ram: parseInt(
            JSON.parse(this.project.server.plan.resources.api).limits.memory
          ),
          database_backend_lim_cpu: parseInt(
            JSON.parse(this.project.server.plan.resources.database).limits.cpu
          ),
          database_backend_lim_ram: parseInt(
            JSON.parse(this.project.server.plan.resources.database).limits
              .memory
          ),
          storage_lim_cdn: parseInt(this.project.server.plan.storage.storage),
        };
        setTimeout(() => {
          this.apiCpuUsageComp?.refetch({
            usage: this.serverDetails.data.api_cpu,
            limit: this.project.server.server_conf.api_backend_lim_cpu,
          });
          this.apiRamUsageComp?.refetch({
            usage: this.serverDetails.data.api_ram,
            limit: this.project.server.server_conf.api_backend_lim_ram,
          });
          this.databaseCpuUsageComp?.refetch({
            usage: this.serverDetails.data.database_cpu,
            limit: this.project.server.server_conf.database_backend_lim_cpu,
          });
          this.databaseRamUsageComp?.refetch({
            usage: this.serverDetails.data.database_ram,
            limit: this.project.server.server_conf.database_backend_lim_ram,
          });
          this.storageCdnUsageComp?.refetch({
            usage: this.serverDetails.data.cdn_storage,
            limit: this.project.server.server_conf.storage_lim_cdn,
          });
          this.apiDiscUsageComp?.refetch({
            usage: this.serverDetails.data.api_disc,
            limit: 1,
          });
          this.databaseDiscUsageComp?.refetch({
            usage: this.serverDetails.data.database_disc,
            limit: 1,
          });
        }, 100);
        this.isProcessing = false;
      });
  }
  setPeriodAndStart(period: number, before: number) {
    this.period = period;
    this.before = before;
    this.isProcessing = true;
    this.setGraphParam(this.project);
  }
  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
