import { Component, Input, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: "doc-function-trigger",
  templateUrl: "./doc-function-trigger.component.html",
  styleUrls: ["./doc-function-trigger.component.scss"],
})
export class DocFunctionTriggerComponent implements OnInit {
  @Input() trigger;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    //   console.log("changess :", changes);
    if (!changes.trigger.firstChange) console.log(changes);
  }
}
