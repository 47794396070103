<div class="alert-content">
  <div class="alerts">
    <mat-list [class.inProgress]="loading.alert">
      <div mat-subheader>
        Alerts
        <mat-icon *ngIf="alerts && alerts.length < 5" (click)="addAlert(null)"
          >notification_add</mat-icon
        >
      </div>
      <mat-spinner
        *ngIf="loading.alert"
        class="spinner-item"
        [diameter]="40"
      ></mat-spinner>
      <mat-list-item class="emty-item" *ngIf="alerts && alerts.length == 0">
        <div mat-line class="no-item">You don't have any alerts yet</div>
      </mat-list-item>
      <mat-list-item *ngFor="let item of alerts" (click)="addAlert(item)">
        <div mat-line>
          <span>Description</span>
          <span> {{ item.description }}</span>
        </div>
        <div mat-line>
          <span>Type</span>
          <span> {{ item.type.split("_").join(" ") | titlecase }}</span>
        </div>
        <div mat-line>
          <span>Channel</span>
          <span> {{ item.channel.split("_").join(" ") | titlecase }}</span>
        </div>
        <div mat-line *ngIf="item.condition != 'none'">
          <span>Condition</span>
          <span>{{ item.condition | titlecase }} {{ item.range }} %</span>
        </div>
        <!-- <div mat-line *ngIf="item.condition != 'none'">
          <span>Range</span> <span>{{ item.range }} %</span>
        </div> -->
        <mat-icon mat-list-icon>chevron_right</mat-icon>
      </mat-list-item>
    </mat-list>
  </div>
  <div class="realizeds">
    <mat-list [class.inProgress]="loading.realized">
      <div mat-subheader>
        Triggered Alerts
        <mat-icon (click)="refreshTriggeredAlerts()">refresh</mat-icon>
      </div>
      <mat-spinner
        *ngIf="loading.realized"
        class="spinner-item"
        [diameter]="40"
      ></mat-spinner>
      <mat-list-item *ngIf="realizeds && realizeds.length == 0">
        <div mat-line class="no-item">
          You don't have any alerts triggered yet
        </div>
      </mat-list-item>
      <mat-list-item *ngFor="let item of realizeds">
        <mat-icon
          mat-list-icon
          class="{{
            item.status == 'success' ? 'alert-success' : 'alert-error'
          }}"
          >{{ item.status == "success" ? "check" : "clear" }}</mat-icon
        >
        <div mat-line>
          <span>Message</span>
          <span> {{ item.message }}</span>
        </div>
        <div mat-line>
          <span>Type</span>
          <span> {{ item.alert.type.split("_").join(" ") | titlecase }}</span>
        </div>
        <div mat-line>
          <span>Channel</span>
          <span>
            {{ item.alert.channel.split("_").join(" ") | titlecase }}</span
          >
        </div>
        <div mat-line *ngIf="item.alert.condition != 'none'">
          <span>Triggered Value</span>
          <span>{{ item?.triggered_value }}%</span>
        </div>

        <div mat-line *ngIf="item.alert.condition != 'none'">
          <span>Condition</span>
          <span
            >{{ item.alert.condition | titlecase }}
            {{ item.alert.range }} %</span
          >
        </div>
        <!-- <div mat-line *ngIf="item.alert.condition != 'none'">
          <span>Range</span> <span>{{ item.alert.range }} %</span>
        </div> -->
        <div mat-line>
          <span>Date</span>
          <span>{{ item.created_at | date: "d/MM/yy HH:mm:ss" }}</span>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
