import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";

@Component({
  selector: "app-add-alert",
  templateUrl: "./add-alert.component.html",
  styleUrls: ["./add-alert.component.scss"],
})
export class AddAlertComponent implements OnInit {
  alertTypes = [
    "api_resource_cpu_load",
    "api_resource_ram_load",
    "api_resource_disc_load",
    "database_resource_ram_load",
    "database_resource_cpu_load",
    "database_resource_disc_load",
    "healt_check",
  ];
  channels = ["mail", "slack", "endpoint"];
  formGroup: FormGroup;
  range = 0;
  // selectedType = "api_resource_cpu_load";
  selectedCondition;
  hideConditions: boolean = false;
  emails: string[] = [];
  removable: boolean = true;
  hasValidEmail: boolean = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  constructor(
    public dialogRef: MatDialogRef<AddAlertComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { project: any; alert: any }
  ) {}

  ngOnInit() {
    this.createForm();
    if (this.data.alert) this.setForm();
    else this.emails = [this.data.project.user.email];
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  createForm() {
    this.formGroup = this.fb.group({
      description: ["", Validators.required],
      type: ["api_resource_cpu_load", Validators.required],
      server: [this.data.project.server._id],
      condition: ["", Validators.required],
      channel: ["mail", Validators.required],
      range: [0],
      requirements: [JSON.stringify(this.emails), Validators.required],
    });
  }
  formatLabel(value: number) {
    return value + "%";
  }
  submit(data) {
    let canSubmit = true;
    if (this.formGroup.invalid) return;
    if (data.channel == "mail")
      if (this.emails.length == 0) {
        canSubmit = false;
        this.hasValidEmail = false;
        setTimeout(() => {
          this.hasValidEmail = true;
        }, 1500);
      } else data.requirements = JSON.stringify(this.emails);
    data.range = this.range;

    if (this.data.alert) data._id = this.data.alert._id;
    console.log("data", data, this.emails);
    if (canSubmit) this.dialogRef.close(data);
  }
  typeChange(event) {
    if (event.value == "healt_check") {
      // this.hideConditions = true;
      this.formGroup.controls["condition"].setValue("none");
      this.range = 0;
    } else {
      // this.hideConditions = false;
      this.formGroup.controls["condition"].setValue("");
    }
  }
  channelChange(event){
    if (event.value != "mail") {
      this.formGroup.controls['requirements'].setValue("")
    }else{
      this.formGroup.controls['requirements'].setValue(JSON.stringify(this.emails))
    }
  }
  setForm() {
    this.formGroup.controls["condition"].setValue(this.data.alert.condition);
    this.formGroup.controls["type"].setValue(this.data.alert.type);
    this.formGroup.controls["description"].setValue(
      this.data.alert.description
    );
    this.formGroup.controls["channel"].setValue(this.data.alert.channel);
    this.formGroup.controls["range"].setValue(this.data.alert.range);
    this.range = this.data.alert.range;
    // this.hideConditions = this.data.alert.condition == "none";
    // this.selectedType = this.data.alert.type;
    // console.log("test :", JSON.parse(this.data.alert.requirements));
    if (this.data.alert.channel == "mail")
      this.emails = JSON.parse(this.data.alert.requirements);
    else
      this.formGroup.controls["requirements"].setValue(
        this.data.alert.requirements
      );
  }

  addKeywordFromInput(event: MatChipInputEvent) {
    if (event.value) {
      if (!this.validateEmail(event.value)) {
        this.hasValidEmail = false;
        setTimeout(() => {
          this.hasValidEmail = true;
        }, 1500);
      } else {
        this.emails = [...this.emails, event.value];
        event.input.value = "";
      }
    }
  }

  removeKeyword(keyword: string) {
    let index = this.emails.indexOf(keyword);
    this.emails.splice(index, 1);
  }
  removeAlert() {
    this.dialogRef.close({ _id: this.data.alert._id, willDelete: true });
  }
  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  // validateURL(str) {
  //   var re = new RegExp(
  //     "^(https?:\\/\\/)?" + // protocol
  //       "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
  //       "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
  //       "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
  //       "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
  //       "(\\#[-a-z\\d_]*)?$",
  //     "i"
  //   ); // fragment locator
  //   return !!re.test(str);
  // }
}
