import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { UsageComponent } from "src/app/chart/usage/usage.component";
import { ObserverService } from "../../services/observer.service";

@Component({
  selector: "app-graphics",
  templateUrl: "./graphics.component.html",
  styleUrls: ["./graphics.component.scss"],
})
export class GraphicsComponent implements OnInit {
  serverDetails: any;
  period: number = 120; //Default period
  before: number = 1;
  isProcessing: boolean;
  interval;

  @Input() project;
  @Input() multipleProjectSelected;

  @ViewChild("apiCpuUsageComp") apiCpuUsageComp: UsageComponent;
  @ViewChild("apiRamUsageComp") apiRamUsageComp: UsageComponent;
  @ViewChild("databaseCpuUsageComp") databaseCpuUsageComp: UsageComponent;
  @ViewChild("databaseRamUsageComp") databaseRamUsageComp: UsageComponent;
  @ViewChild("storageCdnUsageComp") storageCdnUsageComp: UsageComponent;
  @ViewChild("apiDiscUsageComp") apiDiscUsageComp: UsageComponent;
  @ViewChild("databaseDiscUsageComp") databaseDiscUsageComp: UsageComponent;

  constructor(
    private observerService: ObserverService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setGraphParam();
    this.interval = setInterval(() => {
      this.setGraphParam();
    }, 60000);
  }

  setGraphParam() {
    let startTime = new Date();
    startTime.setHours(new Date().getHours() - this.before);
    this.observerService
      .getServerDetails(
        this.project.server._id,
        startTime,
        new Date(),
        this.period
      )
      .toPromise()
      .then((data) => {
        this.serverDetails = data;
        if (
          this.serverDetails.data.cdn_storage &&
          Array.isArray(this.serverDetails.data?.cdn_storage)
        )
          this.serverDetails.data?.cdn_storage.forEach((element) => {
            //  element.value.doubleValue = element.value.doubleValue / (1024 * 1024);
            element.value.doubleValue =
              element.value.doubleValue /
              (10 * 1000 * 1024 * 1024) /
              parseInt(this.project.server.plan.storage.storage);
            return element;
          });
        else this.serverDetails.data.cdn_storage = [];

        this.project.server.server_conf = {
          api_backend_lim_cpu: parseInt(
            JSON.parse(this.project.server.plan.resources.api).limits.cpu
          ),
          api_backend_lim_ram: parseInt(
            JSON.parse(this.project.server.plan.resources.api).limits.memory
          ),
          database_backend_lim_cpu: parseInt(
            JSON.parse(this.project.server.plan.resources.database).limits.cpu
          ),
          database_backend_lim_ram: parseInt(
            JSON.parse(this.project.server.plan.resources.database).limits
              .memory
          ),
          storage_lim_cdn: parseInt(this.project.server.plan.storage.storage),
        };
        setTimeout(() => {
          this.apiCpuUsageComp.refetch({
            usage: this.serverDetails.data.api_cpu,
            limit: this.project.server.server_conf.api_backend_lim_cpu,
          });
          this.apiRamUsageComp.refetch({
            usage: this.serverDetails.data.api_ram,
            limit: this.project.server.server_conf.api_backend_lim_ram,
          });
          this.databaseCpuUsageComp.refetch({
            usage: this.serverDetails.data.database_cpu,
            limit: this.project.server.server_conf.database_backend_lim_cpu,
          });
          this.databaseRamUsageComp.refetch({
            usage: this.serverDetails.data.database_ram,
            limit: this.project.server.server_conf.database_backend_lim_ram,
          });
          this.storageCdnUsageComp.refetch({
            usage: this.serverDetails.data.cdn_storage,
            limit: this.project.server.server_conf.storage_lim_cdn,
          });
          this.apiDiscUsageComp.refetch({
            usage: this.serverDetails.data.api_disc,
            limit: this.project.server.server_conf.storage_lim_cdn,
          });
          this.databaseDiscUsageComp.refetch({
            usage: this.serverDetails.data.database_disc,
            limit: this.project.server.server_conf.storage_lim_cdn,
          });
        }, 100);
        this.isProcessing = false;
      });
  }
  setPeriodAndStart(period: number, before: number) {
    this.period = period;
    this.before = before;
    this.isProcessing = true;
    this.setGraphParam();
  }
  openStandAlone(path: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([this.project._id, "graph", path])
    );

    window.open(url, "_blank");
  }
  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
