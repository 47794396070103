import { Component } from "@angular/core";
import { AuthService } from "./services/auth.service";
import { take } from "rxjs/operators";
import { fromEvent } from 'rxjs';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "spica-observer";
  token;
  user_id;
  user;
  auth_loading: boolean = false;
  host = location.href;
  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.user = this.authService.getUser();
    const iframe: any = document.getElementById('hq-iframe');
    fromEvent(iframe, 'load').pipe(take(1)).subscribe(_ => {
      this.onLoad()
      iframe.contentWindow.postMessage(
        "exchange",
        "https://hq.spicaengine.com/api"
      );
    })
  }

  onLoad() {
    
    window.addEventListener("message", ({ origin, data }) => {
      if (origin != window.origin) {
        const { key, authorization } = data;
        this.token = authorization;
        const apiKey = key;
        //if new token is different from previous one

        if (this.token && !this.authService.getHqToken()) {
          this.authService.setHqToken(this.token);
          this.setUserChange();
          window.location.reload();
        }

        if (this.token != this.authService.getHqToken()) {
          this.startAuthLoading();
          this.token == null
            ? this.authService.deleteLocalStorage()
            : this.authService.setHqToken(this.token);
          this.setUserChange();
        }
      }
    });

  }

  startAuthLoading() {
    this.auth_loading = true;
  }

  stopAuthLoading() {
    this.auth_loading = false;
  }
  setUserChange() {
    this.authService.user_onchange.pipe(take(1)).subscribe((user_id) => {
      this.user_id = user_id;
      this.stopAuthLoading();
    });
  }
}
