import { ChangeDetectorRef } from "@angular/core";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { InterfaceModalComponent } from "../interface-modal/interface-modal.component";

@Component({
  selector: "doc-rest",
  templateUrl: "./doc-rest.component.html",
  styleUrls: ["./doc-rest.component.scss"],
})
export class DocRestComponent implements OnInit {
  possibleAPIs = [
    { method: "GET ALL", path: "data" },
    { method: "GET", path: "data/<ENTRY_ID>" },
    { method: "POST", path: "data/<ENTRY_ID>" },
    { method: "PATCH", path: "data/<ENTRY_ID>" },
    { method: "PUT", path: "data/<ENTRY_ID>" },
    { method: "DELETE", path: "data/<ENTRY_ID>" },
  ];
  exampleValues = {
    string: () => "Lorem ipsum",
    textarea: () =>
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    number: () => Math.random() * 100,
    date: () => new Date(),
    richtext: () =>
      "<b>Lorem ipsum</b><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>",
    relation: () => "5f199ae89051b7645ebab132",
    boolean: () => true,
    array: () => ["Lorem", "Ipsum"],
    object: () => {
      return { foo: "Lorem", bar: "Ipsum" };
    },
  };
  exampleObjects = {};
  activatedAPIs: string[] = [];
  activeBucket;
  @Input() buckets;
  @Input() activeProject;
  @Input() pdfView:boolean=false;
  constructor(private cdr: ChangeDetectorRef, private dialog: MatDialog) {}

  ngOnInit(): void {}

  toggleAPI(bucketId, api, fields): void {
    if (this.activatedAPIs.includes(api)) {
      this.activatedAPIs.splice(this.activatedAPIs.indexOf(api), 1);
    } else {
      this.activatedAPIs.push(api);
      this.exampleObjects[bucketId] = {};
      this.changeexampleObjects(bucketId, fields);
    }
  }

  changeexampleObjects(bucketId, fields) {
    let fieldValues = Object.entries(fields).map(
      (field) => this.exampleValues[field[1]["type"]] || null
    );
    Object.entries(fields).forEach((field, index) => {
      this.exampleObjects[bucketId][field[0]] = fieldValues[index]();
    });
  }

  export(){
    let dialogRef = this.dialog.open(InterfaceModalComponent, {
      height: '80%',
      width: '80%',
      data: {buckets: this.buckets}
    });
  }
}
