import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DocumentationComponent } from "./pages/documentation/documentation.component";
import { DocRestComponent } from "./components/doc-rest/doc-rest.component";

import { HttpClientModule } from "@angular/common/http";
import { DocRestPropertiesComponent } from "./components/doc-rest-properties/doc-rest-properties.component";
import { InterfaceModalComponent } from "./components/interface-modal/interface-modal.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ChartsModule } from "ng2-charts";

import { MatMenuModule } from "@angular/material/menu";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { MatTabsModule } from "@angular/material/tabs";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatCardModule } from "@angular/material/card";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { UsageComponent } from "./chart/usage/usage.component";
import { MatNativeDateModule } from "@angular/material/core";
import { DocFunctionsComponent } from "./components/doc-functions/doc-functions.component";
import { DocFunctionTriggerComponent } from "./components/doc-function-trigger/doc-function-trigger.component";
import { ResourceActivitiesComponent } from "./components/resource-activities/resource-activities.component";
import { GraphicsComponent } from "./components/graphics/graphics.component";
import { LogsComponent } from "./components/logs/logs.component";
import { AlertsComponent } from "./components/alerts/alerts.component";
import { MatListModule } from "@angular/material/list";
import { AddAlertComponent } from "./components/add-alert/add-alert.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSliderModule } from "@angular/material/slider";
import { MatInputModule } from "@angular/material/input";
import { MatChipsModule } from "@angular/material/chips";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { GraphStandaloneComponent } from "./components/graph-standalone/graph-standalone.component";
import { HistogramChartComponent } from "./components/histogram-chart/histogram-chart.component";

@NgModule({
  declarations: [
    AppComponent,
    DocumentationComponent,
    DocRestComponent,
    DocRestPropertiesComponent,
    InterfaceModalComponent,
    DocFunctionsComponent,
    DocFunctionTriggerComponent,
    ResourceActivitiesComponent,
    GraphicsComponent,
    UsageComponent,
    LogsComponent,
    AlertsComponent,
    AddAlertComponent,
    GraphStandaloneComponent,
    HistogramChartComponent,
  ],
  imports: [
    BrowserModule,
    MatNativeDateModule,
    MatDatepickerModule,
    AppRoutingModule,
    HttpClientModule,
    MatButtonModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonToggleModule,
    ClipboardModule,
    MatTabsModule,
    MatIconModule,
    MatSelectModule,
    MatCardModule,
    ChartsModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatListModule,
    MatSliderModule,
    MatInputModule,
    MatChipsModule,

    ScrollingModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
