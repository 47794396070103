import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "doc-rest-properties",
  templateUrl: "./doc-rest-properties.component.html",
  styleUrls: ["./doc-rest-properties.component.scss"],
})
export class DocRestPropertiesComponent implements OnInit {
  @Input() properties;
  constructor() {}

  ngOnInit(): void {
    this.properties = Object.entries(this.properties).map(
      (property) => property[1]
    );
    this.properties = this.properties.sort((a, b) =>
      a?.title?.localeCompare(b?.title)
    );
  }
}
