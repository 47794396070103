import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { GraphStandaloneComponent } from "./components/graph-standalone/graph-standalone.component";
import { DocumentationComponent } from "./pages/documentation/documentation.component";

const routes: Routes = [
  { path: "", component: DocumentationComponent },
  { path: ":id/graph/:type", component: GraphStandaloneComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
