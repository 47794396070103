import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ObserverService {
  observerPatterns;
  alertBucketId = "61951e7cfeea15002c569c1c";
  realizedAlertBucketId = "61964a65feea15002c56a424";

  constructor(private http: HttpClient) {}

  getServerDetails(id: string, startDate: Date, endDate: Date, period: number) {
    return this.http.post(
      "https://hq.spicaengine.com/api/fn-execute/getServerDetails",
      {
        server_id: id,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        period,
      },
      {
        headers: this.setHeader(),
      }
    );
  }
  setHeader() {
    let header = new HttpHeaders({
      authorization: "IDENTITY " + localStorage.getItem("hq_token"),
    });
    return header;
  }
  getAlerts(server_id) {
    return this.http.get(
      `https://hq.spicaengine.com/api/bucket/${this.alertBucketId}/data?filter={"server":"${server_id}"}`,
      {
        headers: this.setHeader(),
      }
    );
  }
  getRealizedAlerts(server_id) {
    return this.http.get(
      `https://hq.spicaengine.com/api/bucket/${this.realizedAlertBucketId}/data?filter={"alert.server":"${server_id}"}&sort={"_id":-1}`,
      {
        headers: this.setHeader(),
      }
    );
  }
  addAlert(data) {
    return this.http.post(
      `https://hq.spicaengine.com/api/bucket/${this.alertBucketId}/data`,
      data,
      {
        headers: this.setHeader(),
      }
    );
  }
  updateAlert(alert) {
    return this.http.put(
      `https://hq.spicaengine.com/api/bucket/${this.alertBucketId}/data/${alert._id}`,
      alert,
      {
        headers: this.setHeader(),
      }
    );
  }
  deleteAlert(id) {
    return this.http.delete(
      `https://hq.spicaengine.com/api/bucket/${this.alertBucketId}/data/${id}`,
      {
        headers: this.setHeader(),
      }
    );
  }
  deleteRealizedAlert(id) {
    return this.http.delete(
      `https://hq.spicaengine.com/api/bucket/${this.realizedAlertBucketId}/data/${id}`,
      {
        headers: this.setHeader(),
      }
    );
  }
}
