<mat-spinner
  class="z-2 page spinner"
  [diameter]="50"
  *ngIf="!serverDetails"
></mat-spinner>
<div *ngIf="serverDetails" class="graphic">
  <div class="graphic-content">
    <div class="period">
      <mat-button-toggle-group aria-label="Time Interval">
        <mat-button-toggle
          [class.overlay-color]="this.period == 120"
          mat-raised-button
          (click)="setPeriodAndStart(120, 1)"
          >1h</mat-button-toggle
        >
        <mat-button-toggle
          [class.overlay-color]="this.period == 900"
          mat-raised-button
          (click)="setPeriodAndStart(900, 6)"
          >6h</mat-button-toggle
        >
        <mat-button-toggle
          [class.overlay-color]="this.period == 3600"
          mat-raised-button
          (click)="setPeriodAndStart(3600, 24)"
          >1d</mat-button-toggle
        >
        <mat-button-toggle
          [class.overlay-color]="this.period == 21600"
          mat-raised-button
          (click)="setPeriodAndStart(21600, 168)"
          >7g</mat-button-toggle
        >
        <mat-button-toggle
          [class.overlay-color]="this.period == 86400"
          mat-raised-button
          (click)="setPeriodAndStart(86400, 720)"
          >30d</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="charts">
    <div class="cpu" *ngIf="chartType == 'apiresourcecpu'">
      <mat-card [class.overlay-color]="isProcessing">
        <mat-spinner
          class="z-2 page spinner"
          [diameter]="50"
          *ngIf="isProcessing"
        ></mat-spinner>
        <h4>Api Resource CPU Usage</h4>
        <app-usage
          #apiCpuUsageComp
          [title]="'CPU Usage'"
          [border]="'#01b4bc'"
          [data]="{
            usage: serverDetails.data?.api_cpu,
            limit: project.server.server_conf?.api_backend_lim_cpu
          }"
        >
        </app-usage>
      </mat-card>
    </div>
    <div class="ram" *ngIf="chartType == 'apiresourceram'">
      <mat-card [class.overlay-color]="isProcessing">
        <mat-spinner
          class="graph spinner"
          [diameter]="30"
          *ngIf="isProcessing"
        ></mat-spinner>
        <h4>Api Resource RAM Usage</h4>
        <app-usage
          #apiRamUsageComp
          [title]="'RAM Usage'"
          [border]="'#fa8925'"
          [data]="{
            usage: serverDetails.data?.api_ram,
            limit: project.server.server_conf?.api_backend_lim_ram
          }"
        >
        </app-usage>
      </mat-card>
    </div>
    <div class="cpu" *ngIf="chartType == 'databaseresourcecpu'">
      <mat-card [class.overlay-color]="isProcessing">
        <mat-spinner
          class="graph spinner"
          [diameter]="30"
          *ngIf="isProcessing"
        ></mat-spinner>
        <h4>Database Resource CPU Usage</h4>
        <app-usage
          #databaseCpuUsageComp
          [title]="'CPU Usage'"
          [border]="'#01b4bc'"
          [data]="{
            usage: serverDetails.data?.database_cpu,
            limit: project.server.server_conf?.database_backend_lim_cpu
          }"
        >
        </app-usage>
      </mat-card>
    </div>
    <div class="ram" *ngIf="chartType == 'databaseresourceram'">
      <mat-card [class.overlay-color]="isProcessing">
        <mat-spinner
          class="graph spinner"
          [diameter]="30"
          *ngIf="isProcessing"
        ></mat-spinner>
        <h4>Database Resource RAM Usage</h4>
        <app-usage
          #databaseRamUsageComp
          [title]="'RAM Usage'"
          [border]="'#fa8925'"
          [data]="{
            usage: serverDetails.data?.database_ram,
            limit: project.server.server_conf?.database_backend_lim_ram
          }"
        >
        </app-usage>
      </mat-card>
    </div>
    <div class="storage" *ngIf="chartType == 'storagecdn'">
      <mat-card [class.overlay-color]="isProcessing">
        <mat-spinner
          class="graph spinner"
          [diameter]="30"
          *ngIf="isProcessing"
        ></mat-spinner>
        <h4>Storage CDN Usage</h4>
        <app-usage
          #storageCdnUsageComp
          [title]="'Storage CDN Usage'"
          [border]="'#01b4bc'"
          [data]="{
            usage: serverDetails.data?.cdn_storage,
            limit: project.server.server_conf?.storage_lim_cdn
          }"
        >
        </app-usage>
      </mat-card>
    </div>

    <div class="apidisc" *ngIf="chartType == 'apidisc'">
      <mat-card [class.overlay-color]="isProcessing">
        <mat-spinner
          class="graph spinner"
          [diameter]="30"
          *ngIf="isProcessing"
        ></mat-spinner>
        <h4>Api Disc Usage</h4>
        <app-usage
          #apiDiscUsageComp
          [title]="'Api Disc Usage'"
          [border]="'#0956ee'"
          [data]="{
            usage: serverDetails.data?.api_disc,
            limit: 1
          }"
        >
        </app-usage>
      </mat-card>
    </div>
    <div class="databasedisc" *ngIf="chartType == 'databasedisc'">
      <mat-card [class.overlay-color]="isProcessing">
        <mat-spinner
          class="graph spinner"
          [diameter]="30"
          *ngIf="isProcessing"
        ></mat-spinner>
        <h4>Database Disc Usage</h4>
        <app-usage
          #databaseDiscUsageComp
          [title]="'Database Disc Usage'"
          [border]="'#91e4bd'"
          [data]="{
            usage: serverDetails.data?.database_disc,
            limit: 1
          }"
        >
        </app-usage>
      </mat-card>
    </div>
  </div>
</div>
