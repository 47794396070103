import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { SpicaClient, SpicaResource } from './spica.facade';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  
  private spicaClient;
  public resources;
  constructor(public authService: AuthService) { 
    this.spicaClient = new SpicaClient("https://hq.spicaengine.com/api", this.authService.getHqToken());
    this.resources = {
      projects: new SpicaResource("5dd912440566406ec8f0d756", this.spicaClient)    }
  }
}
