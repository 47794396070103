import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  SimpleChanges,
} from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { WebSocketSubject } from "rxjs/webSocket";

import * as Bucket from "@spica-devkit/bucket";
import { HttpClient } from "@angular/common/http";
import { Project } from "src/app/interfaces/project";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { switchMap, takeUntil, tap } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { PdfDownloadService } from "./../../services/pdf-download.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-documentation",
  templateUrl: "./documentation.component.html",
  styleUrls: ["./documentation.component.scss"],
})
export class DocumentationComponent implements OnInit {
  wsRefresher: Subject<number> = new Subject();
  wsRefresher2 = new BehaviorSubject(undefined);
  loadingProject: boolean = false;
  userProjects: Observable<Project[]>;
  downloadable: boolean = false;
  functionUrl = "https://hq.spicaengine.com/api/fn-execute/";
  isLoggedUser: boolean = false;
  selectedProjectIDs: String[] = [];
  tabIndex = 0;
  selectProjectIndex: number;
  projectSelected: boolean = false;
  host = location.href;
  destroy$: Subject<boolean> = new Subject<boolean>();
  tabs = [
    {
      value: 0,
      name: "Logs",
    },
    {
      value: 1,
      name: "Graphics",
    },
    {
      value: 2,
      name: "Alerts",
    },
    {
      value: 3,
      name: "Bucket List",
    },
    {
      value: 4,
      name: "Function List",
    },
  ];
  selectedFormat: string = "monitoring";
  selectedProjects: {
    project: Project;
    activeDocumentation: { buckets?: Bucket.Bucket[]; functions?: any };
    logs: any;
    activeLog: string;
    getLiveLogs: boolean;
    resourceActivities: any;
    tab: number;
  }[] = [];
  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private http: HttpClient,
    private dialog: MatDialog,
    private router: Router,
    private pdfDownloadService: PdfDownloadService
  ) {}

  ngOnInit(): void {
    this.isLoggedUser = this.authService.getUser() ? true : false;
    if(!this.isLoggedUser) return 
    this.userProjects = this.dataService.resources.projects
      .getAll({
        queryParams: {
          relation: "server.plan",
          filter: {
            "user._id": { $ne: "" },
            $and: [
              { "user.identity_id": this.authService.getUser()._id },
              {
                "server.observer_activated_date": {
                  $lt: `Date(${new Date().toISOString()})`,
                },
              },
              { status: { $in: ["active", "requested", "denied", "expired"] } },
              { package_code: { $nin: ["on_premise"] } },
            ],
          },
        },
      })
      .pipe(tap((data: Project[]) => {})) as Observable<Project[]>;
  }
  selectProjectTab(e: Array<String>) {
    if (e.length > 0) {
      this.userProjects
        .pipe(
          takeUntil(this.destroy$),
          tap((data: Project[]) => {
            this.selectedProjectIDs = e;
            data.map((project) => {
              if (this.selectedProjectIDs.includes(project._id)) {
                this.selectProject(project);
              }
            });

            // Remove unselected elements
          })
        )
        .subscribe();
    } else {
      this.projectSelected = false;
      this.selectedProjects = [];
    }
  }
  selectProjectDashboard(project: Project) {
    this.selectedProjectIDs = [project._id];
    this.projectSelected = true;
    this.selectProject(project);
  }
  selectTab(e, id: string) {
    this.selectedProjects.map((proj) => {
      if (proj.project._id == id) {
        proj.tab = e;
      }
    });
  }
  async selectProject(project: Project, fromCloseSocket: boolean = false) {
    let activeDocumentation: { buckets?: Bucket.Bucket[]; functions?: any } = {
      buckets: [],
      functions: null,
    };
    let activeProject = project;
    let getLiveLogs = false;
    let logs: any = [];
    if (!fromCloseSocket) this.loadingProject = true;

    Bucket.initialize({
      publicUrl: `https://${activeProject?.server.ip_address}/api`,
      apikey: activeProject?.server.system_api_key,
    });
    activeDocumentation.buckets = await Bucket.getAll();
    let version = Number(activeProject.server.version.substr(0, 3));
    this.wsRefresher
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() =>
          this.http.get(
            `https://${activeProject.server.ip_address}/api/function/`,
            {
              headers: {
                Authorization: `APIKEY ${activeProject.server.system_api_key}`,
              },
            }
          )
        ),
        switchMap((functions: any[]) => {
          activeDocumentation.functions = functions.map((func) => {
            func["triggers"] = Object.entries(func["triggers"]);
            func["env"] = Object.entries(func["env"]);
            return func;
          });
          return this.wsRefresher2.pipe(
            switchMap(() => {
              if (version > 0.8) {
                return getLiveLogs
                  ? new WebSocketSubject(
                      `wss://${activeProject.server.ip_address}/api/function-logs?sort={"_id":-1}&limit=100&Authorization=APIKEY ${activeProject.server.system_api_key}`
                    ).pipe(takeUntil(this.destroy$))
                  : this.http.get(
                      `https://${activeProject.server.ip_address}/api/function-logs?sort={"_id":-1}&limit=100&channel=stdout`,
                      {
                        headers: {
                          Authorization: `APIKEY ${activeProject.server.system_api_key}`,
                        },
                      }
                    );
              } else {
                return new WebSocketSubject(
                  `wss://${activeProject.server.ip_address}/api/function/logs?sort={"_id":-1}&limit=100&Authorization=APIKEY ${activeProject.server.system_api_key}`
                ).pipe(takeUntil(this.destroy$));
              }
            })
          );
        })
      )
      .subscribe(
        (res: any) => {
          let document = getLiveLogs || version < 0.9 ? res.document : res;
          // if (version < 0.9) document = res.document;
          if (document && !logs?.some((lg) => lg._id == document?._id)) {
            if (getLiveLogs || version < 0.9) {
              document.function = activeDocumentation?.functions.filter(
                (f) => f._id == document?.function
              )[0]?.name;
            }
            logs?.splice(0, 0, document);
          }
          if (logs?.length > 100) logs?.pop();
          logs = Array.isArray(logs[0]) && logs[0] ? logs[0] : logs;
          let alreadyExist = this.selectedProjects.filter(
            (selectedProject) => selectedProject.project._id == project._id
          );
          if (!alreadyExist[0]) {
            this.selectedProjects.push({
              project: project,
              activeDocumentation: activeDocumentation,
              logs: logs,
              getLiveLogs: getLiveLogs,
              activeLog: "",
              resourceActivities: null,
              tab: 0,
            });
          } else {
            this.selectedProjects.map((selectedProject) => {
              if (project._id == selectedProject.project._id) {
                selectedProject.logs = logs;
                selectedProject.getLiveLogs = false;
              }
            });
          }
          this.selectedProjects = this.selectedProjects.filter((project) =>
            this.selectedProjectIDs.includes(project.project._id)
          );

          this.loadingProject = false;
        },
        (err) => {
          console.log("error", err);
          this.selectProject(activeProject, true);
        }
      );
    this.wsRefresher.next(0);
  }
  openModal(templete) {
    // this.downloadable = true;
    // this.dialog.open(templete, {
    //   height: "80%",
    //   width: "80%",
    // });
    // setTimeout(() => {
    //   this.downloadable = false;
    // }, this.activeDocumentation.buckets.length * 150);
  }

  generatePdf(data) {
    this.pdfDownloadService.downloadPdf(data).then((test) => {
      this.dialog.closeAll();
      this.downloadable = true;
    });
  }

  async clearLogs(id) {
    this.selectedProjects.map((project) => {
      if (project.project._id == id) {
        project.logs = [];
        for (const fn of project.activeDocumentation.functions) {
          this.http
            .delete(
              `https://${project.project.server.ip_address}/api/function-logs/${fn._id}`,
              {
                headers: {
                  Authorization: `APIKEY ${project.project.server.system_api_key}`,
                },
              }
            )
            .toPromise();
        }
      }
    });
  }
  isLiveLog(live, id) {
    let project = this.selectedProjects.find((proj) => proj.project._id == id);
    if (live != project.getLiveLogs) project.logs = [];
    project.getLiveLogs = live;
    this.wsRefresher2.next(0);
  }
}
