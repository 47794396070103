<div class="header">
  <h2>JSON Schema</h2>
  <div>
    <span class="copy" [class.active]="showCopyInfo">Copied !</span>
    <button
      mat-button
      [cdkCopyToClipboard]="jsonSchema | json"
      (click)="copied()"
    >
      Copy To Clipboard
    </button>
    <!-- <a class="text-white" [cdkCopyToClipboard]="jsonSchema | json" href="https://app.quicktype.io/" target="_blank"><button mat-button>Extract Data Interfaces</button></a> -->
  </div>
</div>
<pre>{{ jsonSchema | json }}</pre>
