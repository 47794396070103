<div style="display: block; height: 100%; width: 100%">
  <canvas
    class="chart"
    baseChart
    [labels]="barChartLabels"
    [datasets]="barChartData"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [chartType]="barChartType"
    [legend]="true"
  >
  </canvas>
</div>
