import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ObserverService } from "src/app/services/observer.service";
import { AddAlertComponent } from "../add-alert/add-alert.component";

@Component({
  selector: "app-alerts",
  templateUrl: "./alerts.component.html",
  styleUrls: ["./alerts.component.scss"],
})
export class AlertsComponent {
  constructor(
    private observerService: ObserverService,
    private dialog: MatDialog
  ) {}
  @Input() project;
  alerts;
  realizeds;
  loading = {
    alert: false,
    realized: false,
  };
  getAlertsOfLicense() {
    return this.observerService.getAlerts(this.project.server._id);
  }
  getRealizedAlerts() {
    return this.observerService.getRealizedAlerts(this.project.server._id);
  }
  addAlert(alert) {
    let dialogRef = this.dialog.open(AddAlertComponent, {
      width: "50%",
      data: { project: this.project, alert: alert },
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then((res) => {
        if (res) {
          this.loading.alert = true;
          if (res._id) {
            console.log(res);
            if (res.willDelete) {
              this.observerService
                .deleteAlert(res._id)
                .toPromise()
                .then(async () => {
                  let index = this.alerts.findIndex(
                    (item) => item._id == res._id
                  );
                  // this.alerts[index] = data;
                  this.alerts.splice(index, 1);
                  let realizedOfDeletedAlert = this.realizeds.filter(
                    (item) => item.alert._id == res._id
                  );
                  if (realizedOfDeletedAlert.length > 0) {
                    for (let item of realizedOfDeletedAlert) {
                      await this.observerService
                        .deleteRealizedAlert(item._id)
                        .toPromise();
                      let indexOfItem = this.realizeds.findIndex(
                        (realized) => realized._id == item._id
                      );
                      this.realizeds.splice(indexOfItem, 1);
                    }
                  }
                  this.loading.alert = false;
                });
            } else {
              this.observerService
                .updateAlert(res)
                .toPromise()
                .then((data) => {
                  let index = this.alerts.findIndex(
                    (item) => item._id == res._id
                  );
                  this.alerts[index] = data;
                  this.loading.alert = false;
                });
            }
          } else {
            this.observerService
              .addAlert(res)
              .toPromise()
              .then((data) => {
                this.alerts.push(data);
                this.loading.alert = false;
              });
          }
        }
      });
  }
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.project) {
      this.project = changes.project.currentValue;
      this.alerts = await this.getAlertsOfLicense().toPromise();
      this.realizeds = await this.getRealizedAlerts().toPromise();
    }
  }
  async refreshTriggeredAlerts() {
    this.loading.realized = true;
    this.realizeds = await this.getRealizedAlerts().toPromise();
    this.loading.realized = false;
  }
}
