<div class="content">
  <div class="project-list" [class.d-none]="projectSelected">
    <div class="project-list-items" *ngIf="isLoggedUser">
      <div class="headings">
        <div class="item">Project Name</div>
        <div class="item">Project Package</div>
        <div class="item">Domain</div>
        <div class="item">Version</div>
      </div>
      <div
        class="project"
        *ngFor="let project of userProjects | async"
        (click)="selectProjectDashboard(project)"
      >
        <div class="item">
          {{ project.project_name }}
        </div>
        <div class="item">
          {{ project.package_code.split("_").join(" ") | titlecase }}
        </div>
        <div class="item" (click)="$event.stopPropagation()">
          <a target="_blank" [href]="'https://' + project.server.ip_address">
            {{ project.server.ip_address }}</a
          >
        </div>
        <div class="item">
          {{ project.server.version }}
        </div>
      </div>
    </div>

    <div class="none-user" *ngIf="!isLoggedUser">
      Please first have to login here
      <a
        [href]="'https://dashboard.spicaengine.com/login?callback=' + host + ''"
        >https://dashboard.spicaengine.com/login</a
      >
    </div>
  </div>
  <div
    *ngIf="isLoggedUser && projectSelected"
    class="documentation"
    [class.d-none]="!projectSelected"
  >
    <div class="projects-step">
      <div class="route">
        <div (click)="projectSelected = false" class="main">
          Projects<mat-icon class="material-icons-outlined">
            chevron_right
          </mat-icon>
        </div>
        <div class="chield">
          <mat-form-field appearance="outline" class="p-rl-10">
            <mat-select
              [value]="selectedProjectIDs"
              placeholder="Projects"
              name="format"
              multiple
              (valueChange)="selectProjectTab($event)"
            >
              <mat-option
                *ngFor="let project of userProjects | async"
                [value]="project._id"
              >
                {{ project.project_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <mat-spinner
      class="z-2"
      [diameter]="50"
      *ngIf="loadingProject"
    ></mat-spinner>
    <div class="project-container" *ngIf="selectedProjects">
      <div
        class="project-screen"
        [class.active]="project.tab == 0"
        *ngFor="let project of selectedProjects"
      >
        <div class="project-screen-tag">
          <div class="project-screen-tag-text">
            {{ project.project.project_name }}
            <mat-form-field [style.width.%]="30">
              <mat-select
                [value]="project.tab"
                name="tabs"
                (valueChange)="selectTab($event, project.project._id)"
              >
                <mat-option *ngFor="let tab of tabs" [value]="tab.value">
                  {{ tab.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div
          class="project-screen-content"
          [class.active]="selectedProjects.length > 1"
        >
          <mat-tab-group
            mat-align-tabs="start"
            color="primary"
            class="overflow-none"
            [(selectedIndex)]="project.tab"
          >
            <mat-tab>
              <logs
                [version]="project ? project.project?.server.version : '0.8'"
                (clear)="clearLogs(project.project._id)"
                (isLive)="isLiveLog($event, project.project._id)"
                [logs]="project.logs"
                [server_id]="project.project?.server._id"
                [activeProject]="project"
              >
              </logs>
            </mat-tab>
            <mat-tab>
              <app-graphics
                [project]="project.project"
                [multipleProjectSelected]="selectedProjectIDs.length > 1"
              ></app-graphics>
            </mat-tab>
            <mat-tab>
              <app-alerts [project]="project.project"></app-alerts>
            </mat-tab>
            <mat-tab>
              <doc-rest
                [activeProject]="project.project"
                [buckets]="project.activeDocumentation?.buckets"
              ></doc-rest>
            </mat-tab>
            <mat-tab>
              <doc-functions
                [activeProject]="project.project"
                [functions]="project.activeDocumentation?.functions"
              >
              </doc-functions>
              <resource-activities
                [resources]="project.resourceActivities"
              ></resource-activities>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>
