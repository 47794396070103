import { Component, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Bucket } from "@spica-devkit/bucket";

@Component({
  selector: "app-interface-modal",
  templateUrl: "./interface-modal.component.html",
  styleUrls: ["./interface-modal.component.scss"],
})
export class InterfaceModalComponent implements OnInit {
  jsonSchema: object = {
    $ref: "#/definitions/Project",
    definitions: {
      Project: {
        type: "object",
        description: "Project",
        properties: {},
      },
    },
  };

  fieldOrigins = {
    textarea: "string",
    richtext: "string",
    string: "string",
    date: "string",
    number: "number",
    array: "string",
    relation: "string",
    storage: "string",
    object: "object",
    boolean: "boolean",
  };

  showCopyInfo: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { buckets: Bucket[] }) {}

  ngOnInit(): void {
    this.data.buckets.forEach((bucket: Bucket) => {
      let properties = bucket.properties;
      let currentBucketName = bucket.title.toLowerCase().split(" ").join("_");
      Object.entries(properties).map(([key, property]) => {
        if (property.type == "relation") {
          let bucketName = this.data.buckets
            .filter((bucket) => bucket._id == property["bucketId"])[0]
            .title.toLowerCase()
            .split(" ")
            .join("_");
          property["$ref"] = `#/definitions/${bucketName}`;
          property["relationType"] = undefined;
          property["primary"] = undefined;
          property["bucketId"] = undefined;
          property["title"] = undefined;
          property["type"] = undefined;
        }
        property["options"] = undefined;
        property["default"] = undefined;
        property["title"] = undefined;
        property = this.transformToOrigin(property);
        return property;
      });
      this.jsonSchema["definitions"][currentBucketName] = {
        type: "object",
        additionalProperties: true,
        properties: { ...properties, _id: { type: "string" } },
      };
      this.jsonSchema["definitions"]["Project"]["properties"][
        currentBucketName
      ] = { $ref: `#/definitions/${currentBucketName}` };
    });
  }

  transformToOrigin(field) {
    if (field["type"]) {
      if (field["type"] == "object") {
        for (let property in field.properties) {
          field.properties[property] = this.transformToOrigin(
            field.properties[property]
          );
        }
      }
      field["type"] = this.fieldOrigins[field["type"] as string];
    }
    return field;
  }
  copied() {
    this.showCopyInfo = true;
    setTimeout(() => {
      this.showCopyInfo = false;
    }, 1200);
  }
}
