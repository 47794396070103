import { HttpClient } from "@angular/common/http";
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { BaseChartDirective } from "ng2-charts";
import { ChartOptions, ChartType } from "chart.js";
@Component({
  selector: "app-histogram-chart",
  templateUrl: "./histogram-chart.component.html",
  styleUrls: ["./histogram-chart.component.scss"],
})
export class HistogramChartComponent {
  isProcessing: boolean;
  @Input("allLogs") allLogs: any;
  @Input("functionsLogs") functionsLogs: object;

  @Input("border") border: any;
  public barChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    showLines: false,
    scales: {
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: true,
            color: "rgba(255,99,132,0.2)",
          },
        },
      ],
      xAxes: [
        {
          display: false,
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  public barChartType: ChartType = "bar";
  public barChartPlugins = [];

  public barChartData: {
    data: number[];
    label: string;
    stack: string;
    setPercentage: number[];
  }[] = [];

  public barChartLabels = [];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor() {}

  createGraphData(
    startDate: Date,
    endDate: Date,
    allLogs = this.allLogs,
    barCount: number = 100
  ) {
    this.barChartLabels = [];
    this.barChartData = [];
    let functionNames = [];
    var currentDate = startDate;
    var milliSeconds = (endDate.getTime() - startDate.getTime()) / barCount;
    while (currentDate <= endDate && this.barChartLabels.length < barCount) {
      currentDate.setTime(currentDate.getTime() + milliSeconds);
      this.barChartLabels.push(new Date(currentDate));
    }
    for (const [key, value] of Object.entries(this.functionsLogs)) {
      if (!functionNames.includes(value[0].function)) {
        functionNames.push(value[0].function);
        this.barChartData.push({
          data: Array(this.barChartLabels.length).fill(0),
          setPercentage: Array(this.barChartLabels.length).fill(
            100 / this.barChartLabels.length
          ),
          label: value[0].function,
          stack: "a",
        });
      }
    }
    console.log(this.functionsLogs);
    allLogs.map((d) => {
      this.barChartLabels.map((date, index) => {
        let logDate = new Date(d.created_at);
        if (
          logDate.getTime() > new Date(date).getTime() &&
          new Date(this.barChartLabels[index + 1]).getTime() > logDate.getTime()
        ) {
          this.barChartData.map((chartData) => {
            if (chartData.label == d.function) {
              chartData.data[index]++;
            }
          });
        }
      });
    });
    console.log(this.barChartData);
  }
}
