import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { BaseChartDirective, Color } from "ng2-charts";
import { ChartOptions } from "chart.js";

@Component({
  selector: "app-usage",
  templateUrl: "./usage.component.html",
  styleUrls: ["./usage.component.scss"],
})
export class UsageComponent {
  @Input("title") title: any;
  @Input("data") data: any;
  @Input("border") border: any;
  public lineChartData = [
    { data: [], label: "" },
    { data: [], label: "Limit %" },
  ];
  public lineChartLabels = [];
  public lineChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
          },
        },
      ],
      xAxes: [
        {
          display: false,
        },
      ],
    },
    annotation: {},
    tooltips: {
      intersect: false,
      backgroundColor: "rgba(255,255,255,0.8)",
      titleFontColor: "rgb(52,52,52)",
      bodyFontColor: "rgb(52,52,52)",
    },
    hover: {
      mode: "nearest",
      intersect: false,
      animationDuration: 100,
    },
  };

  public lineChartColors: Color[] = [
    {
      // red
      borderWidth: 1,
      backgroundColor: "#fff0",
      pointBackgroundColor: this.border,
      pointBorderColor: this.border,
      pointHoverRadius: 2,
      pointRadius: 1,
    },

    {
      // Limit line colors
      borderWidth: 0,
      borderColor: "rgb(200,200,200)",
      backgroundColor: "#fff0",
      pointBackgroundColor: this.border,
      pointBorderColor: this.border,
      pointHoverRadius: 0,
      pointRadius: 1,
    },
  ];
  public lineChartLegend = true;
  public lineChartType = "line";
  public lineChartPlugins = [];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  constructor() {}
  refetch(data) {
    this.data = data;
    this.lineChartData[0].data = [];
    this.lineChartData[1].data = [];
    this.lineChartLabels = [];
    this.data.usage.reverse();
    this.data.usage.map((d) => {
      this.lineChartData[0].data.push(Math.round(d.value.doubleValue * 100));
      this.lineChartLabels.push(new Date(d.interval.startTime).toTimeString());
      this.lineChartData[1].data.push(100);
    });
    this.lineChartColors[0].borderColor = this.border;
    this.lineChartColors.push({ backgroundColor: "#fff0" });
    this.lineChartData[0].label = " " + this.title + " %";

    // this.lineChartData[0].data = [];
    // this.lineChartData[1].data = [];
    // this.lineChartLabels = [];
    // data.usage.reverse();

    // data.usage.map((d) => {
    //   this.lineChartData[0].data.push(Math.round(d.value.doubleValue * 100));
    //   this.lineChartData[1].data.push(100);
    //   this.lineChartLabels.push(new Date(d.interval.startTime).toTimeString());
    // });
    // this.lineChartData[0].data.reverse();
    // this.lineChartLabels.reverse();
    // this.lineChartData = this.lineChartData.slice();
  }
}
