<div class="dialog-header">
  <h1 mat-dialog-title>Add Alert</h1>
  <button (click)="onNoClick()" mat-icon-button color="primary">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <p>What do you want to create an alert for?</p>
  <form [formGroup]="formGroup" (ngSubmit)="submit(formGroup.value)">
    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <input formControlName="description" matInput placeholder="Description" />
      <mat-error *ngIf="formGroup.controls['description'].invalid"
        >Please enter description of alert</mat-error
      >
    </mat-form-field>
    <div class="form-row">
      <mat-form-field appearance="outline" class="row-element">
        <mat-label>Type</mat-label>
        <mat-select
          (selectionChange)="typeChange($event)"
          formControlName="type"
        >
          <mat-option *ngFor="let type of alertTypes" [value]="type">
            {{ type.split("_").join(" ") | titlecase }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup.controls['type'].invalid"
          >Please enter type of alert</mat-error
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="row-element">
        <mat-label>Channel</mat-label>
        <mat-select  (selectionChange)="channelChange($event)" formControlName="channel">
          <mat-option *ngFor="let channel of channels" [value]="channel">
            {{ channel.split("_").join(" ") | titlecase }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup.controls['channel'].invalid"
          >Please enter channel of alert</mat-error
        >
      </mat-form-field>
    </div>
    <mat-form-field
      [class.passive]="formGroup.controls['channel'].value != 'mail'"
      appearance="outline"
    >
      <mat-label>Emails</mat-label>
      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let email of emails"
          [selectable]="true"
          [removable]="true"
          (removed)="removeKeyword(email)"
        >
          {{ email }}
        </mat-chip>
        <input
          placeholder="New Email..."
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addKeywordFromInput($event)"
        />
      </mat-chip-list>
      <span class="error-emails" *ngIf="!hasValidEmail"
        >Please enter valid email</span
      >
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      [class.passive]="formGroup.controls['channel'].value == 'mail'"
    >
      <mat-label
        >{{
          formGroup.controls["channel"].value == "slack"
            ? "Slack Webhook Url"
            : "Endpoint"
        }}
      </mat-label>
      <input formControlName="requirements" matInput placeholder="Url" />
      <mat-error *ngIf="formGroup.controls['requirements'].invalid"
        >Please enter {{formGroup.controls["channel"].value == "slack"
        ? "slack url"
        : "endpoint"}}</mat-error
      >
    </mat-form-field>
    <div
      class="form-row"
      [class.passive]="formGroup.controls['type'].value == 'healt_check'"
    >
      <mat-form-field appearance="outline" class="row-element">
        <mat-label>Condition</mat-label>
        <mat-select formControlName="condition">
          <mat-option
            *ngFor="let contidion of ['under', 'over']"
            [value]="contidion"
          >
            {{ contidion | titlecase }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup.controls['condition'].invalid"
          >Please enter condition of alert</mat-error
        >
      </mat-form-field>
      <div class="slider row-element">
        <div class="slider-header">
          <div class="description">Range</div>
          <div class="value">{{ range }}%</div>
        </div>
        <mat-slider
          thumbLabel
          [displayWith]="formatLabel"
          tickInterval="10"
          step="1"
          min="0"
          max="100"
          [(ngModel)]="range"
          [ngModelOptions]="{ standalone: true }"
          color="primary"
        ></mat-slider>
      </div>
    </div>
    <div class="buttons">
      <button
        mat-button
        type="button"
        *ngIf="data.alert"
        (click)="removeAlert()"
        class="remove"
      >
        Remove
      </button>
      <button mat-button>{{ data.alert ? "Update" : "Create" }}</button>
    </div>
  </form>
</div>
