import * as Bucket from '@spica-devkit/bucket';
import { IndexResult, BucketDocument } from '@spica-devkit/bucket';
import { from, Observable } from 'rxjs';
import { AuthService } from './auth.service';
export class SpicaClient {
  apiUrl: string;
  identity: string;
  constructor(api: string, identity: string) {
    this.apiUrl = api;
    this.identity = identity;
  }


}

export class SpicaResource {
  private resourceBucketId: string;
  constructor(resourceBucketId: string, public spicaClient: SpicaClient) {
    this.resourceBucketId = resourceBucketId;
    Bucket.initialize({
      publicUrl: this.spicaClient.apiUrl,
      identity: this.spicaClient.identity,
    });
  }

  post(data) {
    return from(Bucket.data.insert(this.resourceBucketId, data));
  }

  get(documentId: string, options?: { headers?: {}; queryParams?: {} }): Observable<BucketDocument> {
    return from(Bucket.data.get(this.resourceBucketId, documentId, options)) as Observable<BucketDocument>;
  }

  getAll(options?: { headers?: {}; queryParams?: {} }): Observable<BucketDocument[]>{
    return from(Bucket.data.getAll(this.resourceBucketId, options)) as Observable<BucketDocument[]>;
  }

  update(documentId: string, data) {
    return from(Bucket.data.update(this.resourceBucketId, documentId, data));
  }

  remove(documentId: string) {
    return from(Bucket.data.remove(this.resourceBucketId, documentId));
  }

  getRealtime(documentId: string) {
    return Bucket.data.realtime.get(this.resourceBucketId, documentId);
  }

  getAllRealtime(queryParams?: {}) {
    return Bucket.data.realtime.getAll(this.resourceBucketId, queryParams);
  }
}
